var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invoices,"dense":"","search":_vm.search,"loading":_vm.loading,"items-per-page":250,"sort-by":"invoice_number","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('TableSummaryAlert',{attrs:{"title":'Invoices',"total":_vm.invoiceTotal,"itemCount":_vm.invoices.length}})]},proxy:true},{key:"item.invoice_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAsCurrency("R", item.invoice_total))+" ")]}},{key:"item.invoice_amount_paid",fn:function(ref){
var item = ref.item;
return [(item.invoice_amount_paid === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.formatAsCurrency("R", item.invoice_amount_paid))+" ")]):_c('a',{on:{"click":function($event){_vm.$set(_vm.paymentPreviewDialog, item.invoice_id, true);
          _vm.$refs[item.invoice_id].getCustomerPayments();}}},[_vm._v(_vm._s(_vm.formatAsCurrency("R", item.invoice_amount_paid)))]),_c('InvoicePaymentPreviewModal',{ref:item.invoice_id,attrs:{"paymentPreviewDialog":_vm.paymentPreviewDialog,"value":item,"type":'invoice'}})]}},{key:"item.invoice_amount_due",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAsCurrency("R", item.invoice_amount_due))+" ")]}},{key:"item.invoice_number",fn:function(ref){
          var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.viewInvoice(item)}}},[_vm._v(_vm._s(item.invoice_number))])]}},{key:"no-data",fn:function(){return [_c('EmptyTableSlot',{attrs:{"type":'Invoices'}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }