<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="invoices"
      dense
      :search="search"
      :loading="loading"
      :items-per-page="250"
      sort-by="invoice_number"
      :sort-desc="true"
    >
      <!--Filters-->
      <template v-slot:top>
        <TableSummaryAlert
          :title="'Invoices'"
          :total="invoiceTotal"
          :itemCount="invoices.length"
        />
      </template>
      <!--Format total-->
      <template v-slot:item.invoice_total="{ item }">
        {{ formatAsCurrency("R", item.invoice_total) }}
      </template>
      <!--Format amount paid-->
      <template v-slot:item.invoice_amount_paid="{ item }">
        <span v-if="item.invoice_amount_paid === 0">
          {{ formatAsCurrency("R", item.invoice_amount_paid) }}
        </span>
        <a
          v-else
          @click="
            $set(paymentPreviewDialog, item.invoice_id, true);
            $refs[item.invoice_id].getCustomerPayments();
          "
          >{{ formatAsCurrency("R", item.invoice_amount_paid) }}</a
        >
        <!--View payments-->
        <InvoicePaymentPreviewModal
          :ref="item.invoice_id"
          :paymentPreviewDialog="paymentPreviewDialog"
          :value="item"
          :type="'invoice'"
        />
      </template>
      <!--Format amount due-->
      <template v-slot:item.invoice_amount_due="{ item }">
        {{ formatAsCurrency("R", item.invoice_amount_due) }}
      </template>
      <!--Click to view invoice-->
      <template v-slot:item.invoice_number="{ item }">
        <a @click="viewInvoice(item)">{{ item.invoice_number }}</a>
      </template>
      <!--Empty-->
      <template v-slot:no-data>
        <EmptyTableSlot :type="'Invoices'" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import db from "../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../composables/external";
export default {
  name: "CustomerInvoicesTable",
  props: ["customer_id"],
  components: {
    InvoicePaymentPreviewModal: () =>
      import(
        "../../../Accounting/components/Modals/InvoicePaymentPreviewModal.vue"
      ),
    TableSummaryAlert: () => import("../Alerts/TableSummaryAlert.vue"),
    EmptyTableSlot: () => import("../Tables/EmptyTableSlot.vue"),
  },
  data() {
    return {
      loading: false,
      search: "",
      paymentPreviewDialog: {},
      invoices: [],
      headers: [
        { text: "INV #", value: "invoice_number" },
        { text: "Date", value: "invoice_issue_date" },
        { text: "Amount Paid", value: "invoice_amount_paid", align: "right" },
        {
          text: "Amount Still Due",
          value: "invoice_amount_due",
          align: "right",
        },
        { text: "Total", value: "invoice_total", align: "right" },
      ],
    };
  },
  computed: {
    invoiceTotal() {
      return this.invoices.length !== 0
        ? this.invoices.reduce((total, item) => total + item.invoice_total, 0)
        : 0;
    },
  },
  created() {
    this.getInvoices();
  },
  methods: {
    formatAsCurrency,
    getInvoices() {
      this.loading = true;
      const docRef = db
        .collection("invoices")
        .where("customer.customer_id", "==", this.customer_id);
      docRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.invoices.push(data);
          });
          this.loading = false;
        })
        .catch((error) => error);
    },
    //
    viewInvoice(item) {
      console.log(item.invoice_number);
      //COMMING SOON
    },
  },
};
</script>